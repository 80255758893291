import * as React from 'react'
import Layout from '../../components/layout'
import SublinkContainer from '../../components/wisSublink'
import { StaticImage } from 'gatsby-plugin-image'

const WhatIsShamisenNaguata = () => {
    return (
        <Layout pageTitle='Nagauta shmaisen' subTitle='Nagauta Shamisen' description="An introduction to the Japanese shamisen. Learn about the Naguata shamisen music.">
            <div className='wis-nagauta-page sub-wrapper'>
                <SublinkContainer />
                <section>
                    <h2 className='section-header'>
                        What is Nagauta?
                    </h2>
                    <div className='section-content'>
                        <p>
                            Makoto primarily plays <a href="https://en.wikipedia.org/wiki/Nagauta">Naguata shamisen</a>, a style of traditional Japanese music that is used in kabuki to accompany the dance.
                        </p>
                        <p>
                            Nagauta <span lang='ja'>長唄</span>, (literally, "long song"), is one of the most prominent genres of shamisen music. Traditionally the accompaniment of Kabuki theater and Nihon buyô (Japanese classical dance.), it was first developed around 1740.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='Makoto and her students perform at a concert.'
                                src='../../images/what-is-shamisen/concert4.webp'
                            />
                        </div>
                        <p>
                            Nagauta is the most standard or typical form of Kabuki music, being performed in roughly half of all scenes or pieces in the Kabuki repertoire (the remaining half is divided among tokiwazu, kiyomoto, and other shamisen genres). Nagauta is described as a "lyrical" style, a more purely musical style in contrast to the more "narrative" form of the tokiwazu and kiyomoto styles. As a result, nagauta is the dominant style of music employed in Nihon buyô as well.
                        </p>
                        <p>
                            The standard instrumentation of a nagauta piece consists of singers; shamisen as the main instrument; and performers on the three drums (taiko, ōtsuzumi hip drum, kotsuzumi shoulder drum) and flute (nōkan) found in the hayashi ensemble of the earlier Noh theatre. In nagauta the flute player also sometimes uses a second bamboo flute (take-bue) derived from folk traditions. The drums play both the standard patterns found in the Noh style, and more direct imitations of the rhythmic phrases of the shamisen.
                        </p>
                        <p>
                            The forms of nagauta also reflect interesting combinations of Noh drama structure with Kabuki innovations. In the 19th century nagauta began to be composed and performed in concerts as well as for dance accompaniments. Nagauta shamisen employs a system of notation (tablature) developed in the late 19th or early 20th century. Its repertoire, consisting of over 100 standard pieces (usually up to 20 minutes in length) and new compositions, together with its several guilds of professional and amateur performers, attest to nagauta's continued viability in the 20th century.

                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='Makoto performs at a concert with other musicians'
                                src='../../images/what-is-shamisen/performance3.webp'
                            />
                        </div>
                        <p>
                            Singers, shamisen players, and hayashi musicians (drummers, flute players, other instrumentalists), can all belong to the nagauta genre. Like Kabuki actors, kabuki musicians take on names from the Kabuki tradition; Kineya is the most common surname among onstage nagauta Kabuki singers and shamisen players.
                        </p>
                        <p>
                            REFERENCES: Encyclopedia Brittanica, McQueen Tokita, Alison. "Music in Kabuki: More than Meets the Eye." The Ashgate Research Companion to Japanese Music. Surrey: Ashgate Publishing, 2008. pp 242-245.
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default WhatIsShamisenNaguata